export const APIS = {
    baseurl:  "https://app.smartdietplanner.com:8444/", 
    userBaseUrl:"https://app.smartdietplanner.com/api/", 
    mainUrl: "https://app.smartdietplanner.com" ,
    baseurl4444:  "https://app.smartdietplanner.com:4444",
    baseurlApi:  "https://app.smartdietplanner.com:4444/api/"
   
}
export const SUBAPIS = {
    login: "login",
    refs: "refs",
    summary: "summary",
    search: "customers?",
    updateUser: "customers",
    getToken: "getToken?",
    updateExpDate: "customer-update/update-premium",
    updateReferral: "customer-update/update-referral",
    fetchDietPlanNames: "custom/dietPlan/fetchDietPlanNames",
    fetchSlotsDetails: "custom/dietPlan/fetchSlotsDetails",
    updateSlotsDetails: "custom/dietPlan/updateSlot",
    fetchAllCategories: "custom/dietPlan/fetchAllCategories",
    updateCategory:"custom/dietPlan/updateCategory",
    updateFoodItems:"custom/dietPlan/updateFoodItems",
    fetchFoodDetails: "custom/dietPlan/fetchFoodDetails",
    user: "user",
    defaultDetail: 'defaultDetail',
    fetchCustomerProfile:'fetchCustomerProfile?custId=',
    updateCustomerDetails:'updateCustomerDetails',
    saveDietRecall: "saveDietRecall?userId=",
    getDietRecall: "getDietRecall?userId=",
}
import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router) {
    if (
      !localStorage.getItem("acess_token") &&
      localStorage.getItem("acess_token") == null
    ) {
      this.router.navigate(["./tabs/home"]);
    }
  }

}

import { AlertController } from "@ionic/angular";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpContextToken,
  HttpContext
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, throwError } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { StorageService } from "../../services/storage/storage.service";
import { Router } from "@angular/router";

const BYPASS_LOG = new HttpContextToken(() => false);

export function byPassLog(){
  return new HttpContext().set(BYPASS_LOG, true);
}
@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  constructor(
    private alertController: AlertController,
    private storageService: StorageService,
    private router:Router
  ) {}

  // Intercepts all HTTP requests!
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.context.get(BYPASS_LOG) === true)
      return next.handle(request);
      
      console.log("request",request);

     // localStorage.setItem('personal_token',token);
      
    let token="";
    if(request.url.includes('getOnePlan')){
      token = localStorage.getItem('personal_token');
      console.log("token",token);
    }
    else{
      token = localStorage.getItem('acess_token');
    }

    // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuaWtrIiwicm9sZXMiOltdLCJpc3MiOiJodHRwczovL3Rlc3QuZmlnaHRpdGF3YXkuY29tOjg0NDQvbG9naW4iLCJleHAiOjE2NDQ1NzIzMDZ9.-ZJ63t5-_DpAVx-qp4Exne8pp_XITHM8aJaT3Loe0-M"
    //Authentication by setting header with token value
   
    
    if (token) {
      request = request.clone({
        setHeaders: {
           Authorization: `Bearer ${token}` 
        },
      });
    }

    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        setHeaders: {
          "content-type": "application/json",
        },
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log("event--->>>", event);
        }
        console.log("event123:--", event);
        
        return event;
      }),
      catchError((error: HttpErrorResponse) => {       
        if(error.status==403){
        console.log("token expired:--", event);
        localStorage.clear();
        this.router.navigate(["login"]);
        }
        console.error(error);
        return throwError(error);
      })
    );
  }
}
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChangePlanComponent } from './change-plan/change-plan.component';
import { AddUpdateCustomerComponent } from './components/add-update-customer/add-update-customer.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./consume-v/consume-v.module').then( m => m.ConsumeVPageModule)
  // },
  {
    path: 'search-lead',
    loadChildren: () => import('./search-lead/search-lead.module').then( m => m.SearchLeadPageModule)
  },
  {
    path: 'consume',
    loadChildren: () => import('./consume-v/consume-v.module').then( m => m.ConsumeVPageModule)
  },
  {
    path: "options",
    loadChildren: () =>
      import("./options/options.module").then(m => m.OptionsPageModule),
     
  },
  {
    path: 'food-detail',
    loadChildren: () => import('./food-detail/food-detail.module').then( m => m.FoodDetailPageModule)
  },
  {
    path: 'summary-lead',
    loadChildren: () => import('./summary-lead/summary-lead.module').then( m => m.SummaryLeadPageModule)
  },
  {
    path: 'summary-update',
    loadChildren: () => import('./modal/summary-update/summary-update.module').then( m => m.SummaryUpdatePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'select-diet',
    loadChildren: () => import('./diet-plan/select-diet/select-diet.module').then( m => m.SelectDietPageModule)
  },
  {
    path: 'confirm-diet',
    loadChildren: () => import('./diet-plan/confirm-diet/confirm-diet.module').then( m => m.ConfirmDietPageModule)
  },
  {
    path: 'update-slot',
    loadChildren: () => import('./diet-plan/update-slot/update-slot.module').then( m => m.UpdateSlotPageModule)
  },
  {
    path: 'update-detail-slot',
    loadChildren: () => import('./diet-plan/update-detail-slot/update-detail-slot.module').then( m => m.UpdateDetailSlotPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'users-list',
    loadChildren: () => import('./users-list/users-list.module').then( m => m.UsersListPageModule)
  },
  {
    path: 'consume-v',
    loadChildren: () => import('./consume-v/consume-v.module').then( m => m.ConsumeVPageModule)
  },
  {
    path: 'analysis',
    loadChildren: () => import('./analysis/analysis.module').then( m => m.AnalysisPageModule)
  },
  {
    path: 'options',
    loadChildren: () => import('./options/options.module').then( m => m.OptionsPageModule)
  },
  {
    path: 'options-new',
    loadChildren: () => import('./diet-plan/update-slot-new/update-slot-new-routing.module').then( m => m.UpdateSlotNewPageRoutingModule)
  },
  {
    path: 'addupdate',
    component:AddUpdateCustomerComponent
  },
  {
    path: 'change-plan',
    component:ChangePlanComponent
  },
  {
    path: 'update-slot-new',
    loadChildren: () => import('./diet-plan/update-slot-new/update-slot-new.module').then( m => m.UpdateSlotNewPageModule)
  },
  {
    path: 'deitician-search',
    loadChildren: () => import('./deitician-search/deitician-search.module').then( m => m.DeiticianSearchPageModule)
  },
  {
    path: 'preference-diet',
    loadChildren: () => import('./preference-diet/preference-diet.module').then( m => m.PreferenceDietPageModule)
  },
  {
    path: 'personalise',
    loadChildren: () => import('./personalise/personalise.module').then( m => m.PersonalisePageModule)
  },
  {
    path: 'new-profile',
    loadChildren: () => import('./new-profile/new-profile.module').then( m => m.NewProfilePageModule)
  },
  {
    path: 'update-slot-remarks',
    loadChildren: () => import('./update-slot-remarks/update-slot-remarks.module').then( m => m.UpdateSlotRemarksPageModule)
  },
  {
    path: 'food-item-choice',
    loadChildren: () => import('./food-item-choice/food-item-choice.module').then( m => m.FoodItemChoicePageModule)
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  // },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'customers',
  //   loadChildren: () => import('./customers/customers.module').then( m => m.CustomersPageModule)
  // },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
